// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-dk-js": () => import("./../../../src/pages/contact-dk.js" /* webpackChunkName: "component---src-pages-contact-dk-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact-en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-dev-404-page-js": () => import("./../../../src/pages/dev-404-page.js" /* webpackChunkName: "component---src-pages-dev-404-page-js" */),
  "component---src-pages-impact-calculations-js": () => import("./../../../src/pages/impact-calculations.js" /* webpackChunkName: "component---src-pages-impact-calculations-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offer-js": () => import("./../../../src/pages/offer.js" /* webpackChunkName: "component---src-pages-offer-js" */),
  "component---src-pages-ppa-simulator-js": () => import("./../../../src/pages/ppa-simulator.js" /* webpackChunkName: "component---src-pages-ppa-simulator-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-solution-js": () => import("./../../../src/pages/solution.js" /* webpackChunkName: "component---src-pages-solution-js" */),
  "component---src-pages-spv-list-js": () => import("./../../../src/pages/spv-list.js" /* webpackChunkName: "component---src-pages-spv-list-js" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */)
}

